import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { BrandModel } from '@simploshop-models/brand.model'

class BrandsApiService<M extends BrandModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export function useBrandsApiService() {
    return new BrandsApiService({
        endpoint: '/brands',
    }, BrandModel)
}
