<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <LayoutProductCollectionDetail
        v-model="filterSlug"
        :filters-response="productFiltersResponse"
        :refresh-filters="refreshProductFilters"
        :title="brand?.name ?? '-'"
    >
        <CategoryInfo v-if="brand"
                      class="sim-pc-detail__info"
                      :description="brand.description ?? ''"
        />
    </LayoutProductCollectionDetail>
</template>

<script lang="ts" setup>
import { PRODUCT_CARD_EMBEDS } from '../assets/ts/constants/embeds'

const { page } = useCurrentPage()

const route = useRoute()
const filterSlug = ref<string>(route.fullPath)

const [
    { item: brand },
    {
        data: productFiltersResponse,
        refresh: refreshProductFilters,
    },
] = await Promise.all([
    // Brand
    useBrandsApiService()
        .onlyAttrs([
            BrandModel.ATTR_NAME,
            BrandModel.ATTR_DESCRIPTION,
        ])
        .forId(page.value!.model!.id)
        .useGet(),
    // Products
    useProductsFilterApiService()
        .embed([
            ...PRODUCT_CARD_EMBEDS,
        ])
        .usePost({
            request_uri: filterSlug,
            source: 'brand_detail',
        }, { watch: false }),
])

</script>

<style lang="scss" scoped>

</style>
